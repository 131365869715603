
import './App.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Applist from './pages/applist';
function App() {
  return (
    <Router>
    
    
      <Routes>
      <Route path="/" element={<Applist/>}/>
      </Routes>
   
    
    </Router>
  );
}

export default App;
