import axios from "axios";

const API_BASE_URL = "http://127.0.0.1:8000";
//https://vectorize-ai.appypie.workers.dev/gemini-fetch
// export const AppData = async (data) => {
//     try {
//        // console.log(data);
//         const response = await axios.post(`${API_BASE_URL}/api/createApp/`, data);
//        // const response = await axios.post(`https://vectorize-ai.appypie.workers.dev/gemini-fetch`, data);
         
//        // console.log("response =",response);
//         return response.data;
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//         throw error;
//       }
//  };


 export const AppData = async (data) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify(data);
    
    const requestOptions = {
      method: "POST",
   
      mode: 'cors',
      body: raw,
    
    };
    
    const s = await fetch("https://vectorize-ai.appypie.workers.dev/gemini-fetch", requestOptions)
    //console.log('DATAT == ', data.json() );
    return true;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
};


//  export const Userpromtlist = async (data) => {
//     try {
//       const response = await axios.get(`${API_BASE_URL}/api/alldata/`);
//       console.log("data ==== ", response.data.apps);
//       return response.data.apps;
//     } catch (error) {
//       console.error("Error fetching user data:", error);
//       throw error;
//     }
//   };

export const Userpromtlist = async (data) => {
  try {
    //const myHeaders = new Headers();
    //myHeaders.append("Content-Type", "application/json");
    //console.log("data =",data);
    const raw = JSON.stringify({'user':'ravi'});
    
    const requestOptions = {
      method: "POST",
      mode: 'cors',
      body: raw,
    
    };
    
    const data = await fetch("https://vectorize-ai.appypie.workers.dev/gemini-db", requestOptions);
    //console.log('DATAT == ', s);
    const datad = await data.json();   
    return datad;  
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
};