import React, { useState } from 'react';
import { useMutation,useQuery, useQueryClient } from '@tanstack/react-query';
import { AppData } from '../Apis/appapi';
import {Userpromtlist} from '../Apis/appapi'
//import { useMutation, QueryCache, useQueryClient } from '@tanstack/react-query';
const Applist = () => {


    const { data, isLoading, isError } = useQuery({
        queryKey: ['Userpromtlists'],
        queryFn: Userpromtlist,
        staleTime: 1000 * 10,
        
    });
    console.log ('data ==',data)

    const [processing, setprocessing] = useState(false);
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        queryKey: ['AllAppData'],
        mutationFn: AppData,
        onSuccess: () => {
            setprocessing(false); 
            queryClient.invalidateQueries(["Userpromtlists"]);
        },
        onError: (error) => {
            console.error("An error occurred:", error);
        }
    });

    const [text, setText] = useState("");

    const handleFormSubmit = (e) => {
        e.preventDefault();
        mutate({ data: text });
        setprocessing(true);
        //console.log('data = ',data);
        setText("");
    };

    return (
        <div className="page-wrapper">
          {processing && (
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-50 z-50">
					<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
				</div>
			)}
            <header className="site-head">
                <div className="container-fluid">
                    <div className="logo-wrap">
                        <img src="../../logo-main.svg" className="w-100" alt="" />
                    </div>
                </div>
            </header>
            <main className="grid-wrapper">
                <aside className="sidebar">
                    <div className="h100-per">
                        <div className="ai-quiz">
                            <div className="quiz-area">
                                <div className="grid-container ">
                                  
                                    <div className="d-flex flex-column p-2">
                                    {data && data.map(item => (
                                        <div className="d-flex gap-4 mb-4">
                                            <img
                                                className="rounded-circle"
                                                src="../..//user-icon.png"
                                                style={{ width: 40, height: 40, objectFit: "cover" }}
                                            />
                                           
                                                <div key={item.id} className='w-100'>
                                               
                                                 <iframe className='code-iframe'
                                                    src={`https://preview.mediadirhub.com/desktop-preview/gemini-view/${item.key}`}
                                                    
                                                    title="Preview"
                                                ></iframe>
                                                {item.data.userpromt}
                                                {/* <p className="fw-bold mb-2">{item.data}</p>
                                                <p className="text-muted small">{item.userpromt}</p> */}
                                                </div>
                                           
                                        </div>
                                        
                                    ))}
                                        <div className="d-flex gap-4">
                                            <img
                                                className="rounded-circle"
                                                src="../../main-icon.svg"
                                                style={{ width: 40, height: 40, objectFit: "cover" }}
                                            />
                                            <div>
                                                <p className="fw-bold mb-2">ChatGPT</p>
                                                <p className="text-muted small">
                                                    {text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-btn">
                                <form >
                                    <textarea 
                                        value={text} 
                                        onChange={(e) => setText(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your text here"
                                    />
                                    {/* <button type="submit" className="btn btn-primary">
                                        Save Data
                                    </button> */}
                                     <a  onClick={handleFormSubmit} className="btn btn-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={14}
                                            viewBox="0 0 16 14"
                                            fill="none"
                                        >
                                            <path
                                            d="M1.15123 13.93L15.5005 7.759C16.1665 7.47025 16.1665 6.52975 15.5005 6.241L1.15123 0.0700259C0.608506 -0.169223 0.00822304 0.235025 0.00822304 0.820773L0 4.62401C0 5.03651 0.304253 5.39126 0.715405 5.44076L12.3346 7L0.715405 8.55099C0.304253 8.60874 0 8.96349 0 9.37599L0.00822304 13.1792C0.00822304 13.765 0.608506 14.1692 1.15123 13.93Z"
                                            fill="white"
                                            />
                                        </svg>
                                        </a> 
                                </form>


                            </div>
                        </div>
                    </div>
                </aside>
            </main>
        </div>
    );
}

export default Applist;
